import { gql } from '../types/generated/gql';

export const MEDIA_FEED_POLICY_FRAGMENT = gql(`
  fragment MediaFeedPolicyFields on FeedPolicy{
    broadcastTime
    callSign
    feedSubType
    feedType
    mediaId
    mediaType
  }`);
